<template>
  <Adsense
    v-if="$root.currentUser === null || !$root.currentUser.premium"
    class="adsbygoogle"
           :key="refreshAd"
           style="display:inline-block;"
           data-ad-client="ca-pub-8602467256798312"
           data-ad-slot="8168816861"
           data-ad-format="auto"
           data-full-width-responsive="true"
  >
  </Adsense>
</template>

<script>
export default {
  name: "AdProblemesV",
  mounted() {
    this.startResfresh();
  },
  data() {
    return {
      refreshAd: 0
    }
  },
  methods: {
    startResfresh() {
      // Cada un minut
      setInterval(() => {
        this.refreshAd++;
      }, 30000)
    },
  }
}
</script>

<style scoped>

</style>
