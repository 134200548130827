<template>
  <div style="background-color: white !important; padding-bottom: 120px">
    <v-container v-if="!loading" class="px-12" style="max-width: 1300px !important;">

      <v-row class="mt-5" style="position: relative">
        <v-col cols="12" md="9">

          <!---------------------------------->
          <!--       AUTHOR & SHARE         -->
          <!---------------------------------->
          <div class="d-flex mx-auto" style="max-width: 700px">
            <v-avatar size="48px" style="border: thin #eaeaea solid">
              <v-img height="100%" width="100%" contain
                     src="https://examenselectivitat.cat/img/icon_examenselectivitat.4720e451.png"></v-img>
            </v-avatar>
            <!--        <span class="ml-2">{{article.user_name}}</span>-->
            <div class="d-flex flex-column ml-3">
              <span>Examenselectivitat</span>
              <span class="grey--text">{{ date }}</span>
            </div>

            <v-spacer></v-spacer>

            <v-tooltip
              v-for="button in shareButtons"
              bottom color="grey darken-4">
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot:default="{ hover }">
                  <v-btn :color="hover ? 'black' : '#A8A8A8'"
                         v-on="on"
                         @click="button.shareAction"
                         class="no-background-hover ml-1" :ripple="false"
                         icon>
                    <v-icon size="22">{{button.icon}}</v-icon>
                  </v-btn>
                </v-hover>
              </template>
              {{button.name}}
            </v-tooltip>
          </div>


          <!---------------------------------->
          <!--        TITLES                -->
          <!---------------------------------->
          <div class="d-flex mx-auto mt-10" style="max-width: 700px">
            <div style="flex: 1">
              <h1>{{ title }}</h1>
              <h2 v-if="subtitle"
                  style="font-size: 21px !important; color: #757575 !important;"
                  class="font-weight-regular">{{ subtitle }}</h2>
            </div>
            <v-btn
              v-if="$root.currentUser && $root.currentUser.isAdmin()"
              @click="$router.push({path: '/blog/publish-article/' + id})"
              outlined fab style="border: thin solid !important;" color="primary">
              <v-icon>{{ mdiPencilOutline }}</v-icon>
            </v-btn>
          </div>

          <!---------------------------------->
          <!--        IMAGE                 -->
          <!---------------------------------->
          <v-img v-if="image" :src="image"
                 style="max-width: 700px !important;"
                 contain class="mx-auto my-12 rounded-lg"
          ></v-img>

          <!---------------------------------->
          <!--        ARTICLE HTML          -->
          <!---------------------------------->
          <div class="mt-6 article-html"
               v-html="html"></div>

          <!---------------------------------->
          <!--        BOTTOM SHARE          -->
          <!---------------------------------->
          <div class="d-flex mx-auto mt-12" style="max-width: 700px !important;">
            <v-spacer></v-spacer>

            <v-tooltip
              v-for="button in shareButtons"
              bottom color="grey darken-4">
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot:default="{ hover }">
                  <v-btn :color="hover ? 'black' : '#A8A8A8'"
                         v-on="on"
                         @click="button.shareAction"
                         class="no-background-hover ml-1" :ripple="false"
                         icon>
                    <v-icon size="22">{{button.icon}}</v-icon>
                  </v-btn>
                </v-hover>
              </template>
              {{button.name}}
            </v-tooltip>
          </div>



        </v-col>

        <v-col cols="12" md="3" style="position: sticky !important; position: -webkit-sticky; top: 0; left: 0">
          <div class="d-flex flex-column" style="position: sticky !important; top: 20px; left: 0">
            <span class="font-weight-medium" style="font-size: 18px; color: #0f2e65">Estudia de forma intel·ligent amb Examenselectivitat</span>
            <span style="font-size: 16px"
                  class="grey--text text--darken-2 mt-1">La selectivitat ordenada per temes</span>

            <trending-topics class="mb-3"></trending-topics>

            <ad-problemes-v style="flex: 1; background-color: transparent"></ad-problemes-v>


            <!--            <span class="font-weight-medium" style="font-size: 18px; color: #0f2e65">-->
            <!--              Articles més llegits-->
            <!--            </span>-->


          </div>
        </v-col>

      </v-row>


    </v-container>
  </div>
</template>

<script>
import {mdiPencilOutline} from '/src/assets/mdi.json'
import {mdiTwitter} from '/src/assets/mdi.json'
import {mdiEmailOutline} from '/src/assets/mdi.json'
import {mdiWhatsapp} from '/src/assets/mdi.json'
import {mdiLinkVariant} from '/src/assets/mdi.json'

import TrendingTopics from "./components/TrendingTopics";
import AdProblemesV from "../../components/anuncis/AdProblemesV";

export default {
  metaInfo() {
    return {
      title: `${this.title}`,
      titleTemplate: '%s - Examen selectivitat',
      meta: [
        {charset: 'utf-8'},
        {name: 'description', content: this.subtitle},
        {name: 'viewport', content: 'width=device-width, initial-scale=1'}
      ]
    }
  },
  name: "PublishArticlePage",
  components: {
    TrendingTopics,
    AdProblemesV
  },
  async mounted() {
    await this.getServerContent()
    await this.articleViews()
  },
  data: function () {
    return {
      mdiPencilOutline: mdiPencilOutline,
      id: null,
      loading: false,
      html: null,
      date: null,
      title: null,
      subtitle: null,
      image: null,
      tags: null,
      shareButtons: [
        {
          name: "Compartir per Twitter",
          icon: mdiTwitter,
          shareAction: () => {
            const path = this.$route.params.article_path;
            window.open(`https://twitter.com/intent/tweet?text=${this.title} de @examensele
                             https://examenselectivitat.cat/blog/article/${path}`,
                              'newwindow', 'width=635,height=690'
            )
            this.articleSharedTrends();
          }
        },
        {
          name: "Compartir per Correu",
          icon: mdiEmailOutline,
          shareAction: () => {
            // mailto:?subject=Examenselectivitat.cat al rescate&amp;body=https://examenselectivitat.cat
            const path = this.$route.params.article_path;
            window.open(`mailto:?subject=${this.title}&body=https://examenselectivitat.cat/blog/article/${path}`,
                              'newwindow', 'width=635,height=690'
            )
            this.articleSharedTrends();
          }
        },
        {
          name: "Compartir per Whatsapp",
          icon: mdiWhatsapp,
          shareAction: () => {
            const path = this.$route.params.article_path;
            window.open(`whatsapp://send?text=${this.title} de Examenselectivitat
                             https://examenselectivitat.cat/blog/article/${path}`,
                              'newwindow', 'width=635,height=690',
            )
            this.articleSharedTrends();
          }
        },
        {
          name: "Copiar link",
          icon: mdiLinkVariant,
          shareAction: () => {

            var dummy = document.createElement('input'),
              text = window.location.href;

            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand('copy');
            document.body.removeChild(dummy);

            this.articleSharedTrends();
          }
        }
      ]
    }
  },
  methods: {
    async getServerContent() {
      this.loading = true;
      const {data} = await this.axios.get('/blog-articles/article/path/' + this.$route.params.article_path);
      this.html = data.html;
      this.title = data.title;
      this.id = data.id;
      this.subtitle = data.subtitle;
      this.image = data.image;
      this.date = data.date;
      this.tags = data.tags;
      this.loading = false;
    },
    async articleViews() {
      if (this.$route.params.article_path) {
        await this.axios.put('/blog-articles/trends/' + this.id)
      }
    },
    async articleSharedTrends() {
      if (this.$route.params.article_path) {
        await this.axios.put('/blog-articles/trends-shared/' + this.id)
      }
    },
  }
}
</script>

<style scoped lang="css">

.article-html {
  max-width: 700px !important;
  margin: auto !important;
}


.article-html >>> p {
  display: flex !important;
  margin: 0 !important;
}

.article-html >>> img {
  margin: auto !important;
  border-radius: 8px !important;
  margin-bottom: 32px !important;
}

.article-html >>> h1 {
  color: #292929 !important;
}

.article-html >>> h2 {
  font-size: 19px !important;
  margin-bottom: 4px;
  margin-top: 32px !important;
}

.article-html >>> p, .article-html >>> li, .article-html >>> blockquote {
  font-size: 18px !important;
  color: #292929 !important;
}

.article-html >>> blockquote {
  border-left: solid #e3e3e3 6px;
  padding-left: 10px;
}
</style>
